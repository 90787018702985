$colorBack : #509399;
$fontGreyDark : #327C83;
$colorBack2: #3d4657;
$colorFont: #D1D3D8;

$MainColor : #3D7A6F;
// $SecondColor : #032A2E;
$SecondColor : #000C18;
$ThirdColor: #FDFDFD;
$InputColor : #9EA3AD;
$white : #fff;
$black: #000;
$red : #FFBCBC;
$yellow : #FEF6B2;

$firstFont : 'Bitter',
serif;
$fontFirst : 'Bitter',
serif;
$fontSecond : 'Source Sans Pro',
sans-serif;

*,
::before,
::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    background-color: $ThirdColor;
}

main:not(#login, #register) {
    margin-bottom: 90px;
}

//bulma 

.column {
    padding-bottom: 0 !important;
}

#home .table td {
    padding: .5em 0.25em !important;
}

// fontawesome

.fa-plus {
    // bouton +
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fa-sign-out-alt,
.fa-long-arrow-alt-left {
    color: white;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $fontFirst;
}

input,
p,
a,
button,
span,
table,
.button-delete {
    font-family: $fontSecond;
}

label,
.titre-label {
    font-size: 11px;
}

input:not(#product_reserve, .dateValue, #textInput, .input-label),
.content-export-csv {
    background: $white;
    color: $InputColor;
    font-size: 15px;
    border-radius: 10px;
    border-color: transparent;
    width: 100%;
    box-shadow: 0 2px 4px rgba(51, 51, 51, 0.25);
    padding: 5px 0px 5px 45px;
}

input#inputEmail,
input#inputPassword {
    padding: 10px 0px 10px 45px;
}

#product input,
#edit input {
    padding: 5px 0px 5px 10px;
}

input:focus-visible {
    outline: auto rgba(61, 122, 111, 0.5);
    box-shadow: none !important;
}

button:not(.arrow-optional, .import-csv, .btnDateReload, .button-delete) {
    background: $SecondColor;
}

button:not(.arrow-optional, .import-csv, .btnDateReload),
.button-delete {
    color: white;
    font-size: 18px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(51, 51, 51, 0.25);
    border-color: transparent;
    width: 100%;
    padding: 10px 60px;
}

// bouton de recherche des produits de la home page
#search_product_rayon,
#search_product_reserve {
    display: flex;
}

#search_product_rayon_recherche,
#search_product_reserve_recherche {
    color: white;
    font-size: 10px;
    border-radius: 10px;
    border-color: transparent;
    width: 100%;
    padding: 2px 10px;
}

.produit-ean {
    // pour que la référence n'apparaissent pas directement sur la page des produits sauf action js
    display: none;
}

hr {
    margin: 1.05rem 0;
}

// select{ ???
//     font-family: $fontSecond;
//     font-style: italic;
//     font-size: 15px;
//     margin-left: 40px;
// }

select {
    font-family: $fontFirst;
    font-style: italic;
    font-size: 10px;
}

// push notification 

#modal {
    display: none;
    position: absolute;
    top: 0%;
    height: 110%;
    width: 100%;
    z-index: 10000;

    &:not(#push-permission) {
        background: rgba($color: #666, $alpha: 0.80);
    }

    #push-permission {
        position: absolute;
        top: 41%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: 85%;
        background-color: white;

        .content-notification {
            padding: 20px 40px 10px 50px;

            p {
                font-size: 15px;
            }
        }

        #buttons-notification {
            display: flex;

            button {
                background: none;
                color: $fontGreyDark;
                font-weight: bold;
                border: none;
                box-shadow: none;
                font-size: 13px;
                padding: 10px 0;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 1px;
            }
        }
    }
}


// header 

.header,
.header-login,
.header-register {
    display: flex;
    background-color: $MainColor;
    color: $white;
    box-shadow: 0 2px 4px rgba(51, 51, 51, 0.25);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    padding: 25px;

    svg {
        color: white;
    }

    .svg-inline--fa {
        font-size: 30px;
    }

    .svg-inline--fa {
        font-size: 22px;
    }

    h1 {
        font-size: 1.15em;
        font-weight: 300;
    }

}

.header-login,
.header-register {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    h1 {
        font-size: 30px;
        font-weight: 500;
    }

    h2 {
        font-size: 15px;
        font-style: italic;
    }

    svg {
        color: white;
        font-size: 40px;
    }
}

.header-login {
    height: 45vh;
}

.header-register {
    height: 25vh;
}

#login,
#register,
#user,
#login,
#register,
#user,
#product-show,
#edit,
#edit-rayon,
#new-rayon {
    padding: 50px 40px 0;
}

#product>h2,
#product>div:not(#product #barcode-scanner, .notice-ajout) {
    padding: 20px 40px 0;
}

#login,
#register {
    color: $colorFont;

    h3 {
        color: $SecondColor;
        font-weight: 500;
        font-size: 20px;
    }

    form {

        div {
            position: relative;

            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
                font-size: 16px;
            }
        }
    }
}

// Page de login
#login {

    h4 {
        a {
            font-size: 14px;
            color: $InputColor;
        }
    }
}

// Page de création de compte & de modification des informations

#register,
#login,
#home,
#user,
#product,
#edit,
#product-index,
#error {

    .error-form {
        color: red;
        font-size: 0.75em;
        font-weight: thin;
    }
}

nav {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: $SecondColor;
    width: 100%;
    height: 60px; //70
    z-index: 10000000;

    ul {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        color: white;
        align-items: center;

        li {
            position: relative;

            a {
                display: flex;
                flex-direction: column;
                color: white;

                svg {
                    margin: 0 auto;
                    font-size: 22px !important; //25
                }

                span {
                    font-size: 15px;
                    padding-top: 3px;
                    font-weight: 300;

                    @media (max-width: 767px) {
                        font-size: 10px;
                        font-weight: 100;
                    }
                }

                // &:focus,
                // &:active,
                // &:hover {
                //     color: $ThirdColor !important;
                // }
            }
        }

        .ajout-produit {
            position: relative;

            a {
                position: absolute;
                background: $MainColor;
                border-radius: 50px;
                height: 50px; //60
                width: 50px; //60
                bottom: 0px;
                transform: translateX(-50%);

                &:focus,
                &:active,
                &:hover {
                    background-color: $ThirdColor;
                    color: $MainColor;
                    border: 1px solid $MainColor;
                }


            }

        }
    }
}

#home,
#product-index {

    h2 {
        font-size: 24px;
        padding: 10px 40px 0;
    }

    h3 {
        font-family: $fontSecond;
        font-style: italic;
        font-size: 15px;
        font-weight: 700;
        padding-left: 40px;
    }

    .formSearchProduct {
        justify-content: space-around;

        #search_product_rayon #search_product_rayon_name,
        #search_product_reserve #search_product_reserve_name {
            margin: 0 10px;
        }
    }

    .content-table {
        background: white;
        border: 1px solid rgba(60, 122, 111, 0.10);
        margin: 0 auto;
        margin-left: 5px;
        margin-right: 5px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 20px;

        table {
            width: 95%;
            margin: 0 auto;
            border-collapse: separate;
            border-spacing: 0 5px;

            tr {
                height: 40px;
                font-size: 10px;

                td {
                    word-break: break-word;

                    &:first-child {
                        white-space: nowrap;
                    }

                    &:not(:nth-child(3)) {
                        background-color: white !important;
                    }

                    a {
                        color: $SecondColor;
                        text-decoration: none;
                    }

                }
            }
        }
    }
}

#home {
    .content-titre-ean span {
        width: 100%;
    }

    tbody {
        position: relative;
    }

    .formEditValue {
        display: flex;
        position: relative;

        .dateValue {
            box-shadow: 0 1px 2px rgb(51 51 51 / 25%);
            padding: 1px 4px 1px 5px;
            border: none;
            background: white;
            width: 90%;
            font-size: 1em;
        }

        .btnDateReload {
            background: white;
            border: none;
            padding-right: 8px;
            position: relative;

            svg {
                color: black;
                height: 0.9em;
                width: 0.9em;

                &:active {
                    animation: colorGreen 1.5s ease-out;
                }

            }
        }

        @keyframes colorGreen {

            0% {
                color: green;
                transform: rotate(-15deg);
            }

            100% {
                color: green;
                transform: rotate(-15deg);
            }
        }
    }
}

#product-show {
    h2 {
        font-size: 20px;
    }

    h3 {
        font-family: $fontSecond;
        font-style: italic;
        font-size: 15px;
        font-weight: 700;
    }
}

.content-ul {
    background: white;
    border: 1px solid rgba(60, 122, 111, 0.10);
    margin: 25px auto;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
}

#edit {
    h2:first-child {
        font-size: 24px;
        word-break: break-word;
    }
}

#show {
    h2:first-child {
        word-break: break-word;
    }
}

#user {

    input {
        font-size: 14px;
    }

    input,
    .content-export-csv {
        padding: 8px 0px 8px 10px;
    }

    .button-import-file,
    .content-export-csv {
        font-size: 0.7em;
    }

    .content-upload-file,
    .content-export-csv {
        display: flex;
        position: relative;
    }

    .content-upload-file {
        .import-csv {
            border: none;
            background: transparent;
            color: $MainColor;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .content-export-csv {
        .fa-file-upload {
            color: $MainColor;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .settingsRayon {

        // paramètres des rayons
        table {
            margin: 0 auto;

            @media (max-width: 767px) {
                tbody {
                    font-size: 0.75em;
                }
            }
        }

        a {
            color: $fontGreyDark;
            text-decoration: none;
            text-align: center;
            display: inherit;
        }

        a:not(table a) {
            font-weight: bold;
        }
    }



}


#product_date {

    // pour éviter que le input déborde sur le label pour le form d'ajout de produits
    @media (max-width: 767px) {
        margin-top: 8px;
    }
}

.optional {
    display: none;
}

#product-index {

    table {

        margin: auto;

        .dateDisplay {
            white-space: nowrap
        }

        @media (max-width: 767px) {

            thead {
                font-size: 0.65em;
            }

            tbody {
                font-size: 0.55em;
            }
        }

    }
}


// block comprenant le h2 + le signe fontawesome
.info-optional {
    display: flex;
}

// Bouton afficher les infos facultatives
.arrow-optional {
    background-color: transparent;
    border: none;
    outline: none;
}

// fontawesome signe + & - 
.fa-plus-circle,
.fa-minus-circle {
    color: $MainColor;
}


// Product 

// ajouter un produit 

.flash-notice {
    color: $MainColor;
    font-size: 0.85em;
    // border-radius: 12px;
    // border: 1px solid $MainColor;
}

.flash-error {
    background-color: #8B0000;
    color: white;

    a {
        color: white;

        &:hover {
            text-decoration: underline;
        }
    }
}

.trash {
    // Date inférieure à date setting rayon
    background-color: $red;
    color: $black;
}

.alert {
    // prévention date arrivant a date setting rayon * 3
    background-color: $yellow;
    color: $black;
}

.peremption {
    // péremption
    background-color: #DC143C;
}

.trash:hover,
.alert:hover,
.peremption:hover {
    background-color: $SecondColor !important;
    color: $white;

    a {
        color: $white !important;
    }
}

// La page pour modifier un rayon 
#rayon_name {
    margin-bottom: 20px;
}

.button-update {
    margin-top: 40px;
    margin-bottom: 60px;
}
// Modifier la value du select range des dates pour les pages modifier et créer un rayon
#textInput {
    background-color: none;
    border: none;
    font-size: 15px;
    color: #4a4a4a;
}

footer {
    background-color: $colorBack2;

    a {
        color: white;

        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
}

#barcode-scanner {
    position: relative;

    .drawingBuffer {
        position: absolute;
        top: 0;
        right: 0;
    }

    // @media (max-width: 767px) {
    //     .drawingBuffer {
    //         height: 600px;
    //     }
    // }
}

.content-titre-ean {
    text-transform: uppercase;
}

input#product_title,
input#product_rayon {
    font-size: 12px;
}

.animation-swipe {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -webkit-animation: slide 0.2s forwards;
    -moz-animation: slide 0.2s forwards;
    -ms-animation: slide 0.2s forwards;
    -o-animation: slide 0.2s forwards;
    animation: slide 0.2s forwards;

}

@-webkit-keyframes slide {
    25% {
        -webkit-transform: translateX(-25%);
    }

    50% {
        -webkit-transform: translateX(-50%);
    }

    75% {
        -webkit-transform: translateX(-75%);
    }

    100% {
        -webkit-transform: translateX(-100%);
    }
}

@-moz-keyframes slide {
    25% {
        -moz-transform: translateX(-25%);
    }

    50% {
        -moz-transform: translateX(-50%);
    }

    75% {
        -moz-transform: translateX(-75%);
    }

    100% {
        -moz-transform: translateX(-100%);
    }
}

@-o-keyframes slide {
    25% {
        -o-transform: translateX(-25%);
    }

    50% {
        -o-transform: translateX(-50%);
    }

    75% {
        -o-transform: translateX(-75%);
    }

    100% {
        -o-transform: translateX(-100%);
    }
}

@-ms-keyframes slide {
    25% {
        -ms-transform: translateX(-25%);
    }

    50% {
        -ms-transform: translateX(-50%);
    }

    75% {
        -ms-transform: translateX(-75%);
    }

    100% {
        -ms-transform: translateX(-100%);
    }
}

@keyframes slide {
    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-75%);
    }

    100% {
        transform: translateX(-100%);
    }
}

// .animation-swipe-reverse {
//     -webkit-animation: slide 0.2s forwards;
//     -webkit-animation-delay: 0.1s;
//     animation: slidereverse 0.2s forwards;
//     animation-delay: 0.1s;
// }

// @-webkit-keyframes slidereverse {
//     25% { left: 75%; }
//     50% { left: 50%; }
//     75% { left: 25%; }
//     100% { left: 0%; }
// }

// @keyframes slidereverse {
//     25% { left: 75%; }
//     50% { left: 50%; }
//     75% { left: 25%; }
//     100% { left: 0%; }
// }